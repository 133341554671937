let check_pos_interval
check_pos_interval = setInterval(checkPos, 0)
function checkPos() {
  if (jQuery(window).scrollTop() != 0) {
    window.scrollTo(0, 0)
  }
}

jQuery(document).ready(function($) {

  //init gsap
  gsap.registerPlugin(ScrollTrigger)
  let mm = gsap.matchMedia()

  $('.init_background').addClass('init')

  //scroll to the top of the page
  window.scrollTo(0, 0)
  setTimeout(()=>{
    //prevent chrome from scrolling back to original position on page load
    clearInterval(check_pos_interval)
  }, 500)


  //menu bar logo animation
  let over = false
  window.addEventListener('scroll', (e)=>{
    if (!over && window.scrollY > 10) {
      over = true
      $('.menu_top_logo').addClass('hide')
    } else if (over && window.scrollY <= 10) {
      over = false
      $('.menu_top_logo').removeClass('hide')
    }
  })


  //menu container animation
  let menuTl = gsap.timeline({
    paused: true,
  })
  .fromTo('.menu_container', {
    yPercent: ()=>10,
  }, {
    yPercent: ()=>0,
    duration: .5,
    ease: 'power1.out',
    onComplete() {
      $('.menu_top_logo').addClass('unhide')
    },
  })
  .fromTo('.menu_container .background', {
    width: ()=>'10%',
    height: ()=>'8%',
  }, {
    width: ()=>'185%',
    height: ()=>'110%',
    duration: 1.3,
    ease: 'power1.out',
    // onStart() {
    //   $('.menu_top_logo').addClass('unhide')
    // },
  }, '<')
  .fromTo('.menu_container .background', {
    borderTopLeftRadius: '100vw',
    borderTopRightRadius: '100vw',
  }, {
    borderTopLeftRadius: '0vw',
    borderTopRightRadius: '0vw',
    duration: 1,
    ease: 'none',
    onReverseComplete() {
      $('.menu_top_logo').removeClass('unhide')
    },
  }, '>-.4')
  .fromTo('.menu_container .menu_list > .menu-item', {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    yPercent: 100,
  }, {
    duration: 1,
    ease: 'power2.out',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    yPercent: 0,
    stagger: .2,
  }, '>-.8')
  .fromTo('.menu_container .menu_slide_image', {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    yPercent: 100,
  }, {
    duration: 1,
    ease: 'power2.out',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    yPercent: 0,
  }, '>-1.2')
  .fromTo('.menu_container .menu_slide_title', {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    yPercent: 100,
  }, {
    duration: .8,
    ease: 'power2.out',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    yPercent: 0,
  }, '>-.4')
  .fromTo('.menu_container .menu_slider_button_container', {
    opacity: 0,
  }, {
    duration: .8,
    ease: 'power2.out',
    opacity: 1,
  }, '>-.5')
  .fromTo('.menu_container .menu_info', {
    clipPath: 'polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%)',
  }, {
    duration: 1,
    ease: 'power2.out',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
  }, '>-.5')
  .fromTo('.menu_container .menu_info .info_link', {
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    yPercent: 100,
  }, {
    duration: .8,
    ease: 'power2.out',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    yPercent: 0,
  }, '>-.6')

  let open = false
  $('.menu_button').on('click', ()=>{
    open = !open
    if (open) {
      menuTl.play()
      $('.menu_button').addClass('active')
      $('.menu_container').addClass('active')
      $(document.body).addClass('stop-scroll')
      handleMenuSeparators()
    } else {
      menuTl.reverse()
      $('.menu_button').removeClass('active')
      $('.menu_container').removeClass('active')
      $(document.body).removeClass('stop-scroll')

      //close sub-menu
      submenu_hidden = true
      gsap.timeline()
      .fromTo('.sub_menu_container .sub-menu.active', {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      }, {
        clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
        ease: 'power2.out',
        duration: .8,
        onComplete() {
          prev.removeClass('active')
          menu_item_animating = false
        }
      })
      .to('.sub_menu_container', {
        height: 0,
        ease: 'power2.out',
        duration: .8,
      }, '<')
    }
  })


  if ($('.menu_list').length) {
    $('.menu-item-has-children').each((index, el)=>{
      let id = el.id
      let sub_el = $(el).children('.sub-menu')
      sub_el.attr('id', `sub-${id}`)
      
      $('.sub_menu_container').append(sub_el)
    })
  }


  //menu list separator
  let prev_top_dist = 0
  function handleMenuSeparators() {
    $('.menu_list > .menu-item').each((_, el)=>{
      let top_dist = $(el).offset().top
      if (top_dist != prev_top_dist) {
        $(el).addClass('hide')
        prev_top_dist = top_dist
      } else {
        $(el).removeClass('hide')
      }
    })
  }
  handleMenuSeparators()
  $(window).on('resize', handleMenuSeparators)

  
  //menu container slider
  let menu_item_animating = false
  let submenu_hidden = true
  if ($('.menu_slider').length) {
    const SLIDER_SPEED = 800

    let menu_slider = new Swiper('.menu_slider', {
      loop: true,
      speed: SLIDER_SPEED,
      allowTouchMove: true,
      slidesPerView: 1,
      spaceBetween: '10%',
      centeredSlides: true,
      navigation: {
        prevEl: '#menu-prev',
        nextEl: '#menu-next',
      },
      breakpoints: {
        400: {
          slidesPerView: 1.4,
          spaceBetween: '4%',
        },
        541: {
          slidesPerView: 1.8,
          spaceBetween: '4%',
        },
        769: {
          slidesPerView: 2,
          spaceBetween: '7%',
        },
      }
    });

    //handle menu links
    $('.menu_list > .menu-item').on('click', (e)=>{
      e.preventDefault()

      if ($(e.currentTarget).hasClass('menu-item')) {
        let target_id = e.currentTarget.id.replace('menu-item-', 'menu-slide-')
        let target_index = $(`.${target_id}`).attr('data-swiper-slide-index')
        let current_index = $(`.swiper-slide-active`).attr('data-swiper-slide-index')
        let slide_href = $(e.currentTarget).children('a').attr('href')

        if (!menu_item_animating) {
          let prev = $('.sub_menu_container .sub-menu.active')
          menu_item_animating = true

          gsap.timeline()
          .fromTo('.sub_menu_container .sub-menu.active', {
            clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
          }, {
            clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            ease: 'power2.out',
            duration: .8,
            onComplete() {
              prev.removeClass('active')
              menu_item_animating = false
            }
          })

          if (
            $(e.currentTarget).hasClass('menu-item-has-children') && 
            (current_index != target_index || submenu_hidden)
          ) {
            submenu_hidden = false
            let current = $(`#sub-${e.currentTarget.id}`)
            current.addClass('active')

            gsap.timeline()
            .fromTo(`#sub-${e.currentTarget.id}`, {
              clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
            }, {
              clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
              ease: 'power2.out',
              duration: .8,
            })
            .to('.sub_menu_container', {
              height: current.innerHeight(),
              ease: 'power2.out',
              duration: .8,
              onComplete() {
                prev.removeClass('active')
                menu_item_animating = false
              }
            }, '<')
          } else {
            submenu_hidden = true

            gsap.to('.sub_menu_container', {
              height: 0,
              ease: 'power2.out',
              duration: .8,
              onComplete() {
                prev.removeClass('active')
                menu_item_animating = false
              }
            }, '<')
          }

          if (current_index != target_index) {
            menu_slider.slideToLoop(target_index, SLIDER_SPEED, true)
            
            if (slide_href && slide_href[0] != '#') {
              setTimeout(()=>{
                location.href = slide_href
              }, 800)            
            }

          } else if (slide_href && slide_href[0] != '#') {
            location.href = slide_href            
          }          
        }

      }
    })
  }


  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      let target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        // Get distance from target
        let target_distance = Math.abs(target[0].getBoundingClientRect().top)
        // Get half of the window offset to center the element
        let window_offset = Math.max(0, $(window).innerHeight() - target.innerHeight()) / 2

        $('html, body').animate({
          scrollTop: target.offset().top - window_offset
        }, target_distance / 4, function() {
          // Callback after animation
          // Must change focus!
          let $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });


  //reveal up animations
  $('.reveal-up').each((_,el)=>{
    let tmp_span = `<span> ${$(el).html()} </span>`
    $(el).html(tmp_span)
  })

  function revealUpTrigger(revealContainer) {
    let start = revealContainer.getAttribute('data-start') || `+80px bottom`
    let delay = revealContainer.getAttribute('data-delay') || 0

    gsap.fromTo($(revealContainer).children('span'), {
      yPercent: 100,
    }, {
      delay: delay,
      duration: 1.5,
      ease: 'power2.out',
      yPercent: 0,
      scrollTrigger: {
        trigger: revealContainer,
        toggleActions: "play none none reverse",
        start: start,
        // markers: true,
      }
    });
  }

  //reveal down animations
  function revealDownTrigger(revealContainer) {
    let start = revealContainer.getAttribute('data-start') || `+20% bottom`
    let delay = revealContainer.getAttribute('data-delay') || 0

    gsap.fromTo(revealContainer, {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
    }, {
      delay: delay,
      duration: 1.8,
      ease: 'power2.out',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      scrollTrigger: {
        trigger: revealContainer,
        toggleActions: "play none none reverse",
        start: start,
        // markers: true,
      }
    });
  }


  //image reveal animations
  function imageRevealTrigger(revealContainer) {
    let start = revealContainer.getAttribute('data-start') || '+20% bottom'
    let delay = revealContainer.getAttribute('data-delay') || 0

    mm.add({
      isDesktop: `(min-width: 1024.02px)`,
      isTablet: `(min-width: 768.02px) and (max-width: 1024px)`,
      isMobile: `(max-width: 768px)`,
    },
    (context) => {
      let {isDesktop, isTablet, isMobile} = context.conditions;

      squared = $(revealContainer).hasClass('squared') || (isMobile && $(revealContainer).hasClass('squared-mobile'))

      gsap.fromTo(revealContainer, {
        opacity: 0,
        clipPath: squared ? 'polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%)' : 'inset(5% 5% 5% 5% round 9999px)',
        scale: 1.1,
      }, {
        delay: delay,
        duration: 1,
        ease: 'power2.in',
        opacity: 1,
        clipPath: squared ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' : 'inset(0% 0% 0% 0% round 9999px)',
        scale: 1,
        scrollTrigger: {
          trigger: revealContainer,
          toggleActions: "play none none reverse",
          start: start,
          // markers: true,
        }
      })
    })
  }


  //fade in animations
  function fadeInTrigger(container) {
    let start = container.getAttribute('data-start') || `25% bottom`
    let delay = container.getAttribute('data-delay') || 0

    gsap.fromTo(container, {
      opacity: 0,
    }, {
      opacity: 1,
      delay: delay,
      duration: 1.5,
      ease: 'power1.out',
      scrollTrigger: {
        trigger: container,
        toggleActions: "play none none reverse",
        start: start,
        // markers: true,
      }
    });
  }


  //offers section animations
  function offersTrigger() {
    gsap.timeline({
      scrollTrigger: {
        trigger: '.offers .offers_container',
        toggleActions: "play none none reverse",
        start: `+20% bottom`,
        // markers: true,
      }
    })
    .fromTo('.offers .offers_container .offer .image, .offers .offers_container .offer .preview_data', {
      clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)',
    }, {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
      stagger: 0.2,
      duration: 1.5,
      ease: 'power2.out',
    })
    .fromTo('.offers .offers_container .offer .promo', {
      clipPath: 'inset(50% 50% 50% 50% round 9999px 9999px 9999px 9999px)',
    }, {
      clipPath: 'inset(0% 0% 0% 0% round 9999px 9999px 9999px 9999px)',
      stagger: 0.2,
      duration: 1,
      ease: 'power2.out',
    }, '>-1.2')
  }


  //phrase section animation
  function phraseTrigger() {
    gsap.timeline({
      scrollTrigger: {
        trigger: '.phrase .content',
        toggleActions: "play none none reverse",
        start: `+25% bottom`,
        // markers: true,
      }
    })
    .fromTo('.phrase .background', {
      clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    }, {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      duration: 1.5,
      ease: 'power2.out',
    })
    .fromTo('.phrase .left, .phrase .right', {
      opacity: 0,
      clipPath: 'inset(5% 5% 5% 5% round 9999px)',
      scale: 1.1,
    }, {
      duration: 1,
      ease: 'power2.in',
      opacity: 1,
      clipPath: 'inset(0% 0% 0% 0% round 9999px)',
      scale: 1,
    }, '>-.5')
    .fromTo('.phrase .text span', {
      yPercent: 100,
    }, {
      duration: 1.5,
      ease: 'power2.out',
      yPercent: 0,
    }, '>-.2')
    .fromTo('.phrase .phrase_circle', {
      clipPath: 'circle(0% at 50% 50%)',
    }, {
      duration: 1.5,
      ease: 'power2.out',
      clipPath: 'circle(50% at 50% 50%)',
    }, '>-.5')
  }


  //phrase section animation
  function footerTrigger() {
    gsap.timeline({
      scrollTrigger: {
        trigger: '#main_footer',
        toggleActions: "play none none reverse",
        start: `+40% bottom`,
        end: `+40% bottom`,
        // markers: true,
      }
    })
    .fromTo('#main_footer', {
      clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
    }, {
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      duration: 1.5,
      ease: 'power2.out',
    })
    .fromTo('#main_footer .title span', {
      yPercent: 100,
    }, {
      duration: 1.5,
      ease: 'power2.out',
      yPercent: 0,
    })
    .fromTo('#main_footer .footer_logo', {
      opacity: 0,
    }, {
      duration: 1.2,
      ease: 'power2.out',
      opacity: 1,
    }, '<')
    .fromTo('#main_footer .right', {
      clipPath: 'circle(0% at 50% 50%)',
    }, {
      duration: 1.5,
      ease: 'power2.out',
      clipPath: 'circle(50% at 50% 50%)',
    }, '<')
    .fromTo('#main_footer .content ._text span', {
      yPercent: 100,
    }, {
      duration: 1.5,
      ease: 'power2.out',
      yPercent: 0,
    }, '>-.5')
    .fromTo('#main_footer .footer_bottom', {
      clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)',
    }, {
      duration: 1.2,
      ease: 'none',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    }, '>-.8')
    .fromTo('#main_footer .footer_bottom ._text span', {
      yPercent: 100,
    }, {
      duration: 1.2,
      stagger: 0.3,
      ease: 'power2.out',
      yPercent: 0,
    }, '<+.12')
  }


  //images slider section
  if ($('.images-slider').length) {

    new Swiper('.images-slider .slider', {
      loop: true,
      speed: 800,
      allowTouchMove: true,
      autoplay: {
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
        delay: 1500,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        prevEl: '#mobile-images-prev',
        nextEl: '#mobile-images-next',
      },
      breakpoints: {
        769: {
          allowTouchMove: false,
          navigation: {
            prevEl: '#images-prev',
            nextEl: '#images-next',
          },
        },
      }
    });
  }


  //handle footer circle links
  if ($('#main_footer').length) {
    let tmp_html = $('#main_footer .circle-links').html()
    tmp_html = tmp_html.slice(1,-1) + ' ' + tmp_html[0]
    $('#main_footer .circle-links').html(tmp_html)

    new CircleType(document.querySelector('#main_footer .circle-links'))

    let circle_index = 0
    $('#main_footer .circle-links span').each((_, el)=>{
      let tmp = $(el).html()
      if (tmp != '-' && tmp != ' ') {
        $(el).html(`<a href="${circle_links[circle_index]}">${tmp}</a>`)
      }
      else if (tmp == '-') {
        circle_index = circle_index < circle_links.length-1 ? circle_index+1 : 0
      }
    })
  }


  //handle list intro circle text
  if ($('.list-intro .circle_text').length) {
    let tmp_html = $('.list-intro .circle_text').html()
    tmp_html = tmp_html.slice(1,-1) + ' ' + tmp_html[0]
    $('.list-intro .circle_text').html(tmp_html)

    let list_circle = new CircleType(document.querySelector('.list-intro .circle_text'))

    $('.list-intro .circle_text').width(list_circle.radius() * 2)
    
    $(window).on('resize', ()=>
      $('.list-intro .circle_text').width($('.list-intro .circle_text').height())
    )
  }


  //assuring that scrollTriggers are called in order of appearence along the page
  gsap.utils.toArray(".trigger").forEach(element => {
    if ($(element).hasClass("reveal-up")) {
      revealUpTrigger(element);
    } else if ($(element).hasClass("reveal-down")) {
      revealDownTrigger(element);
    } else if ($(element).hasClass("img-reveal")) {
      imageRevealTrigger(element);
    } else if ($(element).hasClass("fade-in")) {
      fadeInTrigger(element);
    } else if ($(element).hasClass("offers_container")) {
      offersTrigger();
    } else if ($(element).hasClass("phrase")) {
      phraseTrigger()
    } else if ($(element).hasClass("main-footer")) {
      footerTrigger()
    } else if ($(element).hasClass("home_header")) {
      homeHeaderTrigger()
    } else if ($(element).hasClass("philosophy")) {
      philosophyTrigger()
    } else if ($(element).hasClass("list")) {
      listTrigger();
    }
  })
})